<template>
  <div class="tabs-wrapper">
    <div class="tabs-links">
      <router-link v-for="link in this.links" :key="link.name" :to="link.href" class="tab-link">
        {{ link.name }}
      </router-link>
    </div>
    <div class="tabs-content">
      <slot name="additional"></slot>
      <h2 v-if="header">{{ header }}</h2>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    header: {
      type: String
    },
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

h2 {
  font-size: 36px;
  color: $purple;
  text-align: center;
}

.tabs-wrapper {
  position: relative;
}

.tabs-content {
  margin-top: 100px;
  padding: 40px 3rem 40px 3rem;
  background-color: #F3F3F3;
  border-radius: 40px;
  border-top: 2px solid #9B9B9B;
  border-left: 2px solid #9B9B9B;
  border-right: 2px solid #9B9B9B;
  border-bottom: 2px solid #9B9B9B;
}

.tabs-links {
  position: absolute;
  width: 100%;
  top: -70px;
  left: 0;
  text-align: center;
}

.tab-link {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 20px 30px 20px 30px;
  font-size: 24px;
  color: #3F3F3F;
  text-decoration: none;
  -webkit-perspective: 130px;
  perspective: 130px;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #BABABA;
    border-top: 2px solid #9B9B9B;
    border-left: 2px solid #9B9B9B;
    border-right: 2px solid #9B9B9B;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    top: -6px;
    left: 0;
    z-index: -1;
    transform: rotateX(20deg) rotateY(0deg);
    -webkit-transform: translateZ(-5px) rotateX(20deg) rotateY(0deg);
  }
}

.router-link-active {
  z-index: 2;
  &::after {
    height: 101%;
    background-color: #F3F3F3;
  }
}

@media screen and (max-width: $lg) {
  .tabs-content {
    padding: 20px 1.5rem 20px 1.5rem;
    border-radius: 0;
  }

  .tabs-links {
    top: -48px;
  }

  .tab-link {
    padding: 15px;
    font-size: 16px;
  }
}
</style>