<template>
  <modal @close="close">
    <div class="info__wrapper">
      <img :src="$props.image" alt="Мерч">
      <p class="info__text">Поздравляем, ты накопил баллы на супермерч с AR-эффектом</p>
      <div class="info__button-group">
        <button @click="close" class="info__button info__button-main">Далее</button>
      </div>
    </div>
  </modal>
</template>

<script>
import SmallPopup from "@/components/popups/layouts/SmallPopup"

export default {
  name: "PrizePopup",
  components: {
    Modal: SmallPopup
  },

  props: ["image", "slug"],

  data() {
    return {

    }
  },

  methods: {
    close() {
      this.$store.dispatch("bus/closePopup", "prize")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

img {
  filter: grayscale(100%);
}

.info {

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    padding: 55px 0 70px 0;
    text-align: center;
  }

  &__text {
    font-size: 36px;
    color: #3f3f3f;
  }

  &__button {
    display: inline-block;
    width: 336px;
    height: 80px;
    font-family: "Marvin Round";
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    cursor: pointer;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__button-main {
    background-color: $purple;
    color: $white;

    &:hover {
      background-color: $purple;
    }
  }

  &__button-secondary {
    background-color: $white;
    color: $purple;
    border: 3px solid $purple;
  }
}

@media screen and (max-width: $lg) {
  .info {
    &__wrapper {
      row-gap: 20px;
      padding: 55px 0 30px 0;

      img {
        width: 100px;
        height: 100px;
      }
    }

    &__text {
      font-size: 16px;
    }

    &__button-group {
      width: 100%;
    }

    &__button {
      display: block;
      width: 100%;
      height: 50px;
      font-size: 14px;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}
</style>