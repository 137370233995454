<template>
  <base-layout :dif-footer="true">
    <div class="bg">
      <div class="content-wrapper">
        <h1>Личный кабинет</h1>
        <tabs-db :header="'Данные участника'" :links="links">
          <template v-slot:additional>
            <div class="balance-mob">
              <div class="balance-mob__caption">Твой баланс</div>
              <div class="balance-mob__right">
                <div class="balance-mob__score">{{ getPoints}}</div>
                <div class="balance-mob__score-caption">Баллов</div>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div class="profile-wrapper">
              <form @submit.prevent="updateProfile" action="#" class="profile">
                <div class="profile__controls">
                  <label for="name">Имя</label>
                  <input disabled v-model="name"
                         type="text" class="profile__input" id="name" name="name">
                </div>
                <div class="profile__controls">
                  <label for="birth-date">Дата рождения</label>
                  <input disabled v-model="birthday"
                         type="text" class="profile__input" id="birth-date" name="birth-date">
                </div>
                <div class="profile__controls">
                  <label for="e-mail">E-mail</label>
                  <input disabled v-model="email"
                         type="text" class="profile__input" id="e-mail" name="e-mail">
                </div>
                <div class="profile__controls">
                  <label for="phone">Телефон</label>
                  <input disabled v-model="phone" v-mask="'+7(###) ###-##-##'"
                         type="text" class="profile__input" id="phone" name="phone">
                </div>
                <template v-if="isProfileShown">
                  <div class="profile__controls">
                    <input v-model="surname" type="text" class="profile__input-sub" id="last-name" name="last-name"
                           placeholder="Фамилия">
                  </div>
                  <div class="profile__controls">
                    <select v-model="selected" class="profile__input-sub" id="children" name="children">
                      <option v-for="number in 2" :key="number" :selected="number === selected"
                              :value="number">{{ number }}
                      </option>
                    </select>
                  </div>
                  <div class="profile__controls">
                    <input v-model="city" type="text" class="profile__input-sub" id="city" name="city"
                           placeholder="Город">
                  </div>
                  <div class="profile__controls">
                    <input v-model="childName1" type="text" class="profile__input-sub" id="child-name-1"
                           name="child-name-1"
                           placeholder="Имя ребенка">
                  </div>
                  <div class="profile__controls">
                    <date-picker v-model="childBirthDate1" :input-attr="{ id: 'child-birth-date_1' }" :type="'date'"
                                 :format="'DD.MM.YYYY'"
                                 :placeholder="'Дата рождения ребенка'"
                                 :editable="false"/>
                  </div>
                  <div v-show="selected === 2" class="profile__controls">
                    <input v-model="childName2" type="text" class="profile__input-sub" id="child-name-2"
                           name="child-name-2"
                           placeholder="Имя ребенка">
                  </div>
                  <div v-show="selected === 2" class="profile__controls">
                    <date-picker v-model="childBirthDate2" :input-attr="{ id: 'child-birth-date_2' }" :type="'date'"
                                 :format="'DD.MM.YYYY'"
                                 :placeholder="'Дата рождения ребенка'"
                                 :editable="false"/>
                  </div>
                  <div class="profile__controls">
                    <button type="submit" class="profile__button" style="height: 60px;">Сохранить</button>
                  </div>
                </template>
              </form>
              <div class="balance-wrapper">
                <div class="balance">
                  <div class="balance__caption">Твой баланс</div>
                  <div class="balance__score">{{ getPoints }}</div>
                  <div class="balance__score-caption">Баллов</div>
                </div>
              </div>
            </div>
            <div v-show="isTasksShown" class="tasks">
              <router-link to="/academy" class="task-undone">
                <img :src="imageWatch" alt="Посмотреть">
                <div class="task-caption">
                  Посмотреть уроки
                </div>
                <div class="task-score">+1 балл</div>
              </router-link>
<!--              <div class="task-undone">-->
<!--                <img :src="imageWatch" alt="Посмотреть">-->
<!--                <div class="task-caption">-->
<!--                  Посмотреть уроки-->
<!--                </div>-->
<!--                <div class="task-score">+1 балл</div>-->
<!--              </div>-->
              <div @click="openInviteLink" class="task-undone">
                <img :src="imageInvite" alt="Пригласить">
                <div class="task-caption">
                  Пригласить друга
                </div>
                <div class="task-score">+1 балл</div>
              </div>
              <div @click="openProfile(); switchImageToSuccess();" :class="{
                'task-undone': ! isProfileShown,
                'task-done': isProfileShown
              }">
                <img :src="imageApplication" alt="Успешно">
                <div class="task-caption">
                  Заполнить анкету
                </div>
                <div class="task-score">+1 балл</div>
              </div>
              <div @click="buyProduct" class="task-undone">
                <img :src="imageBuy" alt="Купить">
                <div class="task-caption">
                  Покупка продукта
                </div>
                <div class="task-score">+1 балл</div>
              </div>
            </div>
          </template>
        </tabs-db>
        <h2>Копи баллы на розыгрыш призов</h2>
        <div class="prizes">
          <div class="prize prize-enabled">
            <h3>участвуй в розыгрыше
              ежемесячного приза</h3>

              <div class="prize-content-wrapper">
                <div class="prize-content-merch">
                  <div class="prize-image">
                    <img src="../../assets/img/100000.png" alt="Футболка">
                  </div>
                  <div class="prize-description">
                    <div class="description-sub">100 000
                      на обучение</div>
                    <div class="prize-score"><div class="prize-score-left">участие<br>
                      в розыгрыше</div><div class="prize-score-right">15 Баллов</div></div>
                  </div>
                </div>
              </div>

          </div>

          <div v-for="prize in prizes" :key="prize.id"
              class="prize prize-enabled">
            <h3>меняй баллы на
              гарантированный мерч</h3>

            <div class="prize-content-wrapper">
              <div class="prize-content-merch">
                <div class="prize-image">
                  <img :src="getPrizeImg(prize.slug)" alt="Футболка">
                </div>
                <div class="prize-description">
                  <div class="description-sub">{{ getPrizeDescription(prize.slug) }}</div>
                  <div class="prize-score">{{ prize.price }} Баллов</div>
                </div>
              </div>
              <button @click="choosePrize(prize.slug, prize.price)" v-if="prize.isAvailable"
                  class="button-primary">Получить приз</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <invite-popup v-show="getPopupStatus('invite')" />
    <prize-popup v-show="getPopupStatus('prize')" :image="selectedImage" />
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import TabsDb from "@/components/TabsDb"
import DatePicker from 'vue2-datepicker'
import {VueAgile} from "vue-agile"
import InvitePopup from "@/components/popups/InvitePopup"
import PrizePopup from "@/components/popups/PrizePopup"
import {capitalize} from "@/utils/helpers";

export default {
  name: "ProfilePersonal",
  components: {
    BaseLayout,
    TabsDb,
    //agile: VueAgile,
    DatePicker,
    InvitePopup,
    PrizePopup
  },

  data() {
    return {
      certs: [
          {
            key: 500,
            value: 105,
          },
        {
          key: 1000,
          value: 70,
        },
        {
          key: 2000,
          value: 35,
        },

        {
          key: 3000,
          value: 18,
        },
        {
          key: 4000,
          value: 9,
        },
      ],
      prizes: [],
      prizeMap: {
        hat: {
          name: "Супермерч шапка с AR-эффектом",
          img: require("@/assets/img/merch1.png"),
          alt: "Шапка"
        },
        shirt: {
          name: "Супермерч футболка с AR-эффектом",
          img: require("@/assets/img/prize-t-shirt.png"),
          alt: "Футболка"
        },
        hoodie: {
          name: "Супермерч худи с AR-эффектом",
          img: require("@/assets/svg/prize-hoody.svg"),
          alt: "Худи"
        }
      },
      links: [
        {
          name: "Профайл",
          href: "/personal/profile"
        },
        {
          name: "История",
          href: "/personal/history"
        },
        {
          name: "Призы",
          href: "/personal/prizes"
        }
      ],
      name: "",
      birthday: new Date(),
      email: "",
      phone: "",
      isTasksShown: false,
      isProfileShown: false,
      surname: "",
      city: "",
      childName1: "",
      childBirthDate1: new Date(),
      childName2: "",
      childBirthDate2: new Date(),
      selected: 1,
      imageWatch: require("@/assets/svg/icon-task-watch.svg"),
      imageInvite: require("@/assets/svg/icon-task-invite.svg"),
      imageApplication: require("@/assets/svg/icon-task-application.svg"),
      imageBuy: require("@/assets/svg/icon-task-buy.svg"),
      selectedImage: null
    }
  },

  methods: {
    getPrizeImg(name) {
      return this.prizeMap[name].img
    },

    getPrizeDescription(name) {
      return this.prizeMap[name].name
    },

    async choosePrize(slug, points) {
      const response = await this.$store.dispatch("user/choosePrize", { slug, points })

      if (response.error === 1) {
        this.$store.dispatch("bus/notifyError", {
          title: "Произошла ошибка",
          message: response.message.slug[0]
        })

        return
      }

      this.prizes.forEach((item) => {
        if (item.slug === slug) {
          item.isAvailable = false
        }
      })

      this.selectedImage = this.getPrizeImg(slug)
      this.$store.dispatch("bus/openPopup", "prize")
    },

    buyProduct() {
      const button = document.querySelector("#apm-scan-qr")
      this.$store.dispatch("bus/openPopup", "apm")

      button.click()
    },

    openInviteLink() {
      this.$store.dispatch("bus/openPopup", "invite")
    },

    getPopupStatus(name) {
      return this.$store.state.bus.popups[`is${capitalize(name)}Opened`]
    },

    openTasks() {
      this.isTasksShown = true
    },

    openProfile() {
      this.isProfileShown = true
    },

    switchImageToSuccess() {
      this.imageApplication = require("@/assets/svg/icon-task-success.svg")
    },

    updateProfile() {
      const request = {
        surname: this.surname,
        city: this.city,
        children: []
      }

      if (this.childName1 !== "") {
        request.children.push({
          male: 0,
          name: this.childName1,
          age: new Date().getFullYear() - this.childBirthDate1.getFullYear()
        })
      }

      if (this.childName2 !== "" && this.selected === 2) {
        request.children.push({
          male: 0,
          name: this.childName2,
          age: new Date().getFullYear() - this.childBirthDate2.getFullYear()
        })
      }

      this.$store.dispatch("user/updateProfile", request).then(() => {
        this.$store.dispatch("bus/notifySuccess", {
          title: "Успешно",
          message: "Профиль обновлен"
        })
      })
    }
  },

  created() {
    this.name = this.$store.state.user.profile.name
    this.birthday = `${this.$store.state.user.profile.birthday.getDate()}.${this.$store.state.user.profile.birthday.getMonth() + 1}.${this.$store.state.user.profile.birthday.getFullYear()}`
    this.email = this.$store.state.user.profile.email
    this.phone = this.$store.state.user.profile.phone
    this.surname = this.$store.state.user.profile.surname
    this.city = this.$store.state.user.profile.city
    this.selected = this.$store.state.user.profile.children.length !== 0 ? this.$store.state.user.profile.children.length : 1

    let counter = 1

    this.$store.state.user.profile.children.forEach((item) => {
      if (counter === 1) {
        this.childName1 = item.name
        this.childBirthDate1 = item.birthday
      }

      if (counter === 2) {
        this.childName2 = item.name
        this.childBirthDate2 = item.birthday
      }

      counter++
    })

    this.prizes = this.$store.state.user.globalPrizes.map((globPrize) => {
      const userPrize = {
        id: globPrize.id,
        name: globPrize.name,
        price: globPrize.price,
        slug: globPrize.slug,
        isAvailable: true
      }

      let isFound = false

      this.$store.state.user.profile.prizes.forEach((prize) => {
        if (prize.slug === userPrize.slug || isFound === true) {
          isFound = true
        }
      })

      userPrize.isAvailable = ! isFound

      return userPrize
    })
  },

  computed: {
    getPoints() {
      return this.$store.state.user.profile.points
    }
  }
}
</script>

<style>
a {
  text-decoration: none;
}

.prizes .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.prizes .agile__nav-button:after {
  content: url('../../assets/svg/arrow-white.svg');
  position: absolute;
  left: -30px;
  top: 0;
}

.prizes .agile__nav-button--next {
  transform: rotate(180deg);
}

.prizes .agile__actions {
  justify-content: space-between;
  position: absolute;
  bottom: 50%;
  left: 2.5%;
  right: 0;
  width: 95%;
}

.prize-enabled .agile__dots, .prize-disabled .agile__dots {
  position: absolute;
  left: 42%;
  bottom: -230px;
}

.agile__dot {
  display: block;
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 10px;
  transition: width 200ms;
  margin: 0 2.5px;
}

.agile__dot button {
  opacity: 0;
  height: 100%;
  width: 100%;
  color: transparent;
}

.agile__dot--current {
  width: 35px;
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

h1 {
  margin-bottom: 45px;
  font-size: 60px;
  text-align: center;
}

h2 {
  font-size: 36px;
  text-align: center;
}

.bg {
  position: relative;
  background-color: #fff;
  color: #3F3F3F;

  &::before, &::after {
    content: "";
    position: absolute;
    display: block;
    background-size: cover;
  }

  &::before {
    width: 90px;
    height: 100px;
    top: 60px;
    left: 10%;
    //
  }

  &::after {
    width: 100px;
    height: 95px;
    top: 60px;
    right: 10%;
    //
  }
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 45px 0 90px 0;
}

.extra-score {
  position: relative;
  margin-top: 190px;
  padding-bottom: 90px;
  font-size: 24px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 90px;
    height: 100px;
    top: -120px;
    left: 47%;
    background-size: cover;
    background-image: url("../../assets/svg/label-yellow-thunderbolt.svg");
  }
}

.profile-wrapper::after {
  content: "";
  display: block;
  clear: both;
}

.profile {
  display: flex;
  width: 70%;
  margin-bottom: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  float: left;

  &__controls {
    position: relative;
    width: 49%;

    label {
      content: "";
      position: absolute;
      font-family: Montserrat;
      top: 10px;
      left: 30px;
      font-size: 16px;
      color: $gray;
    }

    .mx-datepicker {
      width: 100%;
    }
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    padding-top: 20px;
    padding-left: 30px;
    font-family: "Montserrat";
    font-size: 20px;
    border: 1px solid #DBDBDB;
    border-radius: 45px;

    &:disabled {
      color: $black;
    }
  }

  &__input-sub {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding-left: 30px;
    font-family: "Montserrat";
    font-size: 20px;
    color: #666;
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 45px;
  }

  &__button {
    width: 100%;
    height: 80px;
    font-family: "Marvin Round";
    font-size: 24px;
    background-color: $purple;
    color: $white;
    border-radius: 60px;
    border: none;
    cursor: pointer;
  }
}

.button-primary {
  width: 100%;
  height: 80px;
  font-family: "Marvin Round";
  font-size: 24px;
  background-color: $purple;
  color: $white;
  border-radius: 60px;
  border: none;
  cursor: pointer;
}

.balance-wrapper {
  width: 30%;
  float: left;
  text-align: center;
}

.balance {
  display: inline-block;
  width: 288px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 24px;
  background-color: #3F3F3F;
  color: #fff;
  border-radius: 27px;

  &__score {
    font-size: 74px;
    color: #BABABA;
  }

  &__score-caption {
    color: #BABABA;
  }
}

.balance-mob {
  display: none;
  padding: 30px;
  background-color: $purple;
  border-radius: 27px;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  &__caption, &__right {
    width: 50%;
    float: left;
  }

  &__caption {
    font-size: 24px;
    word-break: break-word;
  }

  &__right {
    box-sizing: border-box;
    padding-right: 10px;
    text-align: right;
  }

  &__score {
    font-size: 64px;
    line-height: 60px;
    color: $yellow;
  }

  &__score-caption {
    font-size: 16px;
    color: $yellow;
  }
}

.tasks {
  display: flex;
  justify-content: space-between;
}

.task-undone, .task-done {
  width: 220px;
  padding: 20px 0 20px 0;
  border-radius: 27px;
  text-align: center;
  cursor: pointer;
}

.task-caption {
  margin: 15px 0 15px 0;
  font-size: 24px;
}

.task-score, .prize-score {
  display: inline-block;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: $purple;
  background-color: #BABABA;
  border-radius: 40px;
}

.task-undone {
  background-color: $yellow;

  .task-caption {
    color: $black;
  }
}

.task-done {
  background-color: $white;
  border: 2px solid $yellow;

  .task-caption {
    color: $yellow;
  }
}

.prizes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 40px;
}

.prize {
  box-sizing: border-box;
  width: 49%;
  height: 520px;
  border-radius: 40px;

  h3 {
    font-size: 30px;
    text-align: center;
  }
}

.prize-enabled, .prize-disabled {
  position: relative;
}

.prize-enabled {
  background-color: #F3F3F3;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  h3 {
    color: $purple;
  }
}

.prize-disabled {
  padding: 0 4rem 0 4rem;
  background-color: $purple;

  h3 {
    color: $yellow;
  }
}

.prize-content {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}

.prize-content-merch {
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  font-size: 24px;
  column-gap: 50px;

  width: 91%;
  padding-left: 8.5%;


  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.prize-image {
  width: 40%;
  img {
    width: 100%;
  }
}

.prize-description {
  box-sizing: border-box;
  width: 60%;
  color: $black;
}

.prize-price {
  margin-top: 15px;
  color: $green;
}

.prize-score {
  margin-top: 20px;
  width: auto;
}

.prize-score-left, .prize-score-right {
  display: inline-block;
}

.prize-score-left {
  margin-right: 10px;
  padding-top: 7px;
  font-size: 10px;
  line-height: 12px;
}

@media screen and (max-width: $lg) {
  h1 {
    font-size: 30px;
  }

  .bg::before, .bg::after {
    background-image: none;
  }

  .profile {
    width: auto;
    flex-direction: column;
    row-gap: 10px;

    &__controls {
      width: 100%;
    }

    &__button {
      height: 70px;
      margin-top: 5px;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 14px;
    }
  }

  .button-primary {
    height: 50px;
    font-size: 14px;
  }

  .balance-wrapper {
    display: none;
  }

  .balance-mob {
    display: block;
  }

  .tasks {
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .task-caption {
    font-size: 16px;
  }

  .task-undone, .task-done {
    width: 160px;

    img {
      width: 70px;
      height: 70px;
    }
  }

  .task-score {
    font-size: 16px;
  }

  .prizes {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    row-gap: 20px;
  }

  .prize {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    border-radius: 14px;

    h3 {
      font-size: 24px;
    }
  }

  .prize-content {
    height: auto;
    flex-direction: column;
    font-size: 16px;

    & img {
      margin-bottom: 20px;
    }
  }

  .prize-content-merch {
    height: auto;
    flex-direction: column;
    font-size: 16px;
  }

  .prize-image {
    img {
      //width: auto;
    }
  }

  .prize-image, .prize-description {
    width: 100%;
    text-align: center;
  }

  .prize-price {
    font-size: 18px;
  }

  .prize-score {
    display: inline-block;
    font-size: 16px;
  }

  .balance-mob__caption {
    color: #fff;
  }

  .balance-mob__score, .balance-mob__score-caption {
    color: #BABABA;
  }
}

img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>